import React, { Component } from 'react'
import { navigate } from 'gatsby'

class IndexPage extends Component {

    componentDidMount() {
        window.location.href = process.env.MEMBERSHIP_PORTAL;
        // let redirect_uri = process.env.MEMBERSHIP_ENDPOINT;
        // console.log(redirect_uri);
        // navigate(`/auth?app_name=arwgame&redirect_uri=${encodeURIComponent(redirect_uri)}`)
    }

    render() {
        return (
            <h1>Redirecting to Membership Portal</h1>
        )
    }
}

export default IndexPage
